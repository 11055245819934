<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="formInline"
          :inline="true"
          label-position="right"
          label-width="82px"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationName">
            <el-select v-model.trim="formInline.operationName" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationName"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <el-button icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
          <router-link :to="{ path: '/operatorFunctionManageAE' }">
            <!-- <el-button type="primary" icon="el-icon-plus" @click="">{{ $t('button.addto') }}</el-button> -->
          </router-link>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!-- <el-table-column type="index" :label="$t('list.index')" :index='indexMethod' width="70" align='center'></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="80"
            align="center"
            v-if="$route.meta.authority.button.edit"
          >
            <template slot-scope="scope">
              <div>
                <router-link :to="{ path: '/operatorFunctionManageAE', query: scope.row }">
                  <el-button type="text" v-if="$route.meta.authority.button.edit">{{ $t('button.edit') }}</el-button>
                </router-link>
                <!-- <el-button type="text" @click='delete1(scope.row)' v-show='scope.row.isAdmin != 1'>删除</el-button> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, setIndex } from "../../../common/js/public.js";
export default {
  name: "operatorFunctionManage",
  data() {
    return {
      roleList: [],
      rowData: "",
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
        },
        {
          prop: "businessTypeName",
          label: "商户业务类型",
        },
        {
          prop: "desc",
          label: "备注",
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "",
          formatter: (row) => {
            return dateFormat(
              new Date(Date.parse(row.createdTime.replace(/-/g, "/"))),
              "yyyy-MM-dd HH:mm"
            );
          },
        },
      ],
      tableData: [],
      formInline: {
        operationName: "",
      },
    };
  },
  methods: {
    resetForm() {
      this.formInline.operationName = "";
      // this.$refs.formInline.resetFields();
    },
    // 选择每行数据
    getRowData(data) {
      this.rowData = data;
    },
    // 操作细则
    handleCommand(command) {
      if (command == 1) {
        this.$router.push({
          path: "/operatorFunctionManageAE",
          query: this.rowData,
        });
      } else if (command == 2) {
        this.$router.push({
          path: "/merchantRoleManageDetail",
          query: this.rowData,
        });
      }
    },
    indexMethod(index) {
      return index + 2;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 删除角色
    delete1(roleData) {
      let obj = {
        method: "post",
        url: "/acb/2.0/managerRoles/forbidOrUse/" + roleData.managerRoleId,
        data: {
          managerRoleState: 3,
        },
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              type: "info",
              message: "删除角色成功",
            });
            this.pageNum = 1;
            this.searchData();
          } else if (res.state == 15) {
            this.$alert("该角色中包含用户，请先解除与用户的关系", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$confirm("此操作将删除角色, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(obj);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除角色",
          });
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/operation/listWithRole", {
          data: {
            page: this.pageNum,
            size: this.pageSize,
            operationName: this.formInline.operationName,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.roleList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  components: {},
  activated() {
    this.searchData();
  },
  created() {
    this.getRoleList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content

  overflow hidden
  width 100%
  .searchWrapper
    overflow hidden
    padding 22px
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
